import contactUsService from "@/services/contactUs.service";

export const contact_us = {
    namespaced      : true,
    state   : {
        contact_us  : [],
        meta        : []
    },
    actions : {
        getContactUs({ commit }, data) {
            return contactUsService.getContactUs(data).then((response) => {
                commit("contactUs", response.data);
                return Promise.resolve(response);
            });
        },
        getCalculator({ commit }, data) {
            return contactUsService.getCalculator(data).then((response) => {
                commit("calculator", response.data);
                return Promise.resolve(response);
            });
        },
        patchContactUs({ commit }, data) { 
            return contactUsService.patchContactUs(data).then((response) => {
                commit("contact_us", response.data.data);
                return Promise.resolve(response);
            });
        }
    },
    mutations   : {
        contactUs(state, data) {
            state.contact_us    = data.data;
            state.meta          = data.meta
        },
        calculator(state, data) {
            state.calculator    = data.data;
            state.meta          = data.meta
        },
        contact_us(state, data) {
            for (let index = 0; index < (state.contact_us).length; index++) {
                if(state.contact_us[index].id == data.id) {
                    state.contact_us.splice(index,1,data);
                    return;
                }
            }
            state.contact_us.unshift(data);
        }
    },
};