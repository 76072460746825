import { createRouter, createWebHistory } from "vue-router";
import Signin from "../views/auth/SignIn.vue";
import ACRecovery from "../views/auth/ACRecovery.vue";
import MainContainer from "@/views/layout/MainLayout.vue";
import NotFound from "../views/layout/NotFound.vue";
import NotAuthorized from "../views/layout/NotAuthorized.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import store from "@/store";

const routes = [{
    path: "/404",
    name: "NotFound",
    component: NotFound,
},
{
    path: "/401",
    name: "NotAuthorized",
    component: NotAuthorized,
},
{
    path: "/signin",
    name: "Signin",
    component: Signin,
},
{
    path: "/steps-to-recover-account",
    name: "ACRecovery",
    component: ACRecovery,
},
{
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
},
{
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
},
{
    path: '/',
    name: 'Home',
    redirect: 'icp-dashboard',
    component: MainContainer,
    meta: {
        title: 'Home',
        authorize: ['dashboard', 'super_admin', 'admin', 'default'],
        $store: store,
    },
    children: [
        {
            path: '/task/tasks',
            name: 'Tasks',
            component: () =>
                import("@/views/task/Task.vue"),
            meta: {
                title: 'Tasks',
                authorize: ['task'],
                $store: store,
            }
        },

        // start:remove
        // {
        //     path: '/market/deal/:id',
        //     name: 'DealsView',
        //     component: () =>
        //         import("@/views/market/Deal.vue"),
        //     meta: {
        //         title: 'DealsView',
        //         authorize: ['deals'],
        //         $store: store,
        //     }
        // },
        // {
        //     path: '/team/teams',
        //     name: 'Teams',
        //     component: () => import('@/views/team/Team.vue'),
        //     meta: {
        //         title: 'Teams',
        //         authorize: ['team'],
        //         $store: store,
        //     }

        // },
        // {
        //     path: '/market/client',
        //     name: 'Clients',
        //     component: () =>
        //         import("@/views/market/Client.vue"),
        //     meta: {
        //         title: 'Clients',
        //         authorize: ['clients'],
        //         $store: store,

        //     }
        // },
        // {
        //     path: '/affiliates',
        //     name: 'Affiliates',
        //     component: () => import('@/views/Affiliate/Affiliate.vue'),
        //     meta: {
        //         title: 'Affiliates',
        //         authorize: ['affiliates'],
        //         $store: store,
        //     }

        // },
        // {
        //     path: '/affiliate-deals',
        //     name: 'Business',
        //     component: () => import('@/views/Affiliate/Business.vue'),
        //     meta: {
        //         title: 'Business',
        //         authorize: ['business'],
        //         $store: store,
        //     }
        // },
        {
            path: '/statistics/statistics',
            name: 'Statistics',
            component: () => import('@/views/statistics/Statistics.vue'),
            meta: {
                title: 'Dashboard',
                authorize: ['dashboard', 'super_admin', 'admin', 'default'],
                $store: store,
            }

        },
        // end:remove

        {
            path: '/blog/manage/blog',
            name: 'Blog',
            component: () => import('@/views/blog/manage/Blog.vue'),
            meta: {
                title: 'Blog',
                authorize: ['post'],
                $store: store,
            }

        },
        {
            path: '/blog/manage/post',
            name: 'Post',
            component: () => import('@/views/blog/manage/Post.vue'),
            meta: {
                title: 'Post',
                authorize: ['post'],
                $store: store,
            }

        },
        {
            path: '/blog/category',
            name: 'Category',
            component: () => import('@/views/blog/Category.vue'),
            meta: {
                title: 'Category',
                authorize: ['category'],
                $store: store,
            }

        },
        {
            path: '/testimonials',
            name: 'Testimonial',
            component: () => import('@/views/Testimonials/Testimonials.vue'),
            meta: {
                title: 'Testimonial',
                authorize: ['testimonial'],
                $store: store,
            }

        },
        {
            path: '/calculator',
            name: 'Calculator',
            component: () => import('@/views/Website/Calculator.vue'),
            meta: {
                title: 'Calculator',
                authorize: ['calculator'],
                $store: store,
            }

        },
        {
            path: '/contact-us',
            name: 'Contact Us',
            component: () => import('@/views/Website/ContactUs.vue'),
            meta: {
                title: 'Contact Us',
                authorize: ['contact_us'],
                $store: store,
            }
        },
        {
            path: '/demo-request',
            name: 'Demo Request',
            component: () => import('@/views/Website/DemoRequest.vue'),
            meta: {
                title: 'Demo Request',
                authorize: ['demo_request'],
                $store: store,
            }
        },
        {
            path: '/facebook',
            name: 'Facebook',
            component: () => import('@/views/Website/FaceBookPage.vue'),
            meta: {
                title: 'Facebook',
                authorize: ['facebook'],
                $store: store,
            }

        },
        {
            path: '/partner/profiles',
            name: 'Partners',
            component: () => import('@/views/Partners/Partners.vue'),
            meta: {
                title: 'Partners',
                authorize: ['partners'],
                $store: store,
            }

        },
        {
            path: '/partners-referrals',
            name: 'Partners Referrals',
            component: () => import('@/views/Partners/Referrals.vue'),
            meta: {
                title: 'Partners Referrals',
                authorize: ['referral_client'],
                $store: store,
            }

        },
        {
            path: '/partners-clients',
            name: 'Partners Contact',
            component: () => import('@/views/Partners/PartnersContact.vue'),
            meta: {
                title: 'Partners Contact',
                authorize: ['partners_client'],
                $store: store,
            }

        },

        
        {
            path: '/interested',
            name: 'Interested',
            component: () => import('@/views/Website/Interested.vue'),
            meta: {
                title: 'Interested',
                authorize: ['interested'],
            }
        },







        // --START---------------------------------------------->
        // start: dashboard
        {
            path: '/icp-dashboard',
            name: 'ICP Dashboard',
            component: () => import('@/views/dashboard/ICPDashboard.vue'),
            meta: {
                title: 'ICP Dashboard',
                authorize: ['dashboard'],
            }
        }
        ,        // end: dashboard
        //start: market
        {
            path: '/market/deals',
            name: 'Deals XX',
            component: () =>
                import("@/views/market/deals/Deals.vue"),
            meta: {
                title: 'Deals',
                authorize: ['deal'],
                $store: store,

            }
        },
        {
            path: '/market/dealdetials/:id',
            name: 'Deals Details',
            component: () =>
                import("@/views/market/deals/DealDetails.vue"),
            meta: {
                title: 'Deals Details',
                authorize: ['deal'],
                $store: store,
            }
        },
        //end: market
        // start: settings
        {
            path: '/settings/api_keys',
            name: 'Api Keys',
            component: () => import('@/views/settings/APIKeys.vue'),
            meta: {
                title: 'Api Keys',
                authorize: ['api_keys', 'super_admin'],
                $store: store,
            }

        },
        {
            path: '/settings/users',
            name: 'Users',
            component: () => import('@/views/settings/Users.vue'),
            meta: {
                title: 'Users',
                authorize: ['users', 'super_admin'],
                $store: store,
            }

        },
        {
            path: '/settings/roles_and_permission',
            name: 'Roles And Permissions',
            component: () => import('@/views/settings/RolesAndPermisson.vue'),
            meta: {
                title: 'Roles And Permissions',
                authorize: ['roles_and_permissions', 'super_admin'],
                $store: store,
            }

        },
        // end: settings
        // start: sales projection
        {
            path: '/sales/sales',
            name: 'Sales',
            component: () => import('@/views/sales/Sales.vue'),
            meta: {
                title: 'Sales',
                authorize: ['sales'],
                $store: store,
            }
        },
        {
            path: '/sales/account_values',
            name: 'Account Value',
            component: () => import('@/views/sales/AccountValue.vue'),
            meta: {
                title: 'Account Value',
                authorize: ['account_value', 'super_admin', 'admin'],
                $store: store,
            }

        },
        {
            path: '/sales/sales_projection',
            name: 'Sales Projection',
            component: () => import('@/views/sales/SalesProjection.vue'),
            meta: {
                title: 'Sales Projection',
                authorize: ['sales_projection', 'super_admin', 'admin'],
                $store: store,
            }
        },
        {
            path: '/sales/email_metrics',
            name: 'Email Metrics',
            component: () => import('@/views/sales/EmailMetrics.vue'),
            meta: {
                title: 'Email Metrics',
                authorize: ['email_metrics', 'super_admin', 'admin'],
                $store: store,
            }
        },
        {
            path: '/sales/client_experience',
            name: 'Client Experience',
            component: () => import('@/views/sales/ClientExpenience.vue'),
            meta: {
                title: 'Client Experience',
                authorize: ['client_experience', 'super_admin', 'admin'],
                $store: store,
            }

        },
        // end: sales projection

        // start: help-desk
        {
            path: '/help-desk/tickets',
            name: 'Tickets',
            component: () => import('@/views/helpDesk/ticketing/Tickets.vue'),
            meta: {
                title: 'Tickets',
                authorize: ['tickets', 'super_admin', 'admin'],
                $store: store,
            }

        },
        {
            path: '/help-desk/ticket-category',
            name: 'Ticket Category',
            component: () => import('@/views/helpDesk/ticketing/Category.vue'),
            meta: {
                title: 'Ticket Category',
                authorize: ['tickets_category', 'super_admin', 'admin'],
                $store: store,
            }

        },
        // end: help-desk

        // Start: Ticketing
        // {
        //     path: 'ticket/ticket',
        //     name: 'Ticket',
        //     component: () => import('@/views/Ticket/Ticket.vue'),
        //     meta: {
        //         title: 'Ticket',
        //         authorize: ['client_experience', 'super_admin', 'admin'],
        //         $store: store,
        //     }
        // },

        // {
        //     path: '/ticket/ticket',
        //     name: 'Ticket',
        //     component: () => import('@/views/Ticket/Ticket.vue'),
        //     meta: {
        //         title: 'Ticket',
        //         authorize: ['admin'],
        //         $store: store,
        //     }
        // },
        // {
        //     path: '/ticket/notes/:ticket_id',
        //     name: 'Ticket Notes',
        //     component: () => import('@/views/Ticket/Notes.vue'),
        //     meta: {
        //         title: 'Ticket Notes',
        //         authorize: ['admin',],
        //         $store: store,
        //     }

        // },
        // End: Ticketing
        // --END---------------------------------------------->
    ]
},
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const { authorize } = to.meta;
    const isAuthenticated = JSON.parse(localStorage.getItem("_user"));
    var show = false;

    if (authorize) {
        try {
            const systemModels = isAuthenticated ? isAuthenticated.system_models.map(sm => sm.slug) : [];
            const userRoles = isAuthenticated ? [isAuthenticated.role.role.slug] : [];

            if (isAuthenticated) {
                if (systemModels.length > 0 || userRoles.length > 0) {
                    const hasModel = systemModels.some(model => to.meta.authorize && to.meta.authorize.includes(model));
                    const hasRole = userRoles.some(role => to.meta.authorize && to.meta.authorize.includes(role));

                    if (hasModel && !hasRole) {
                        show = false;
                        to.meta.$store.dispatch("ual/alert_show", { show });
                        next();
                    } else if (!hasModel && hasRole) {
                        show = false;
                        to.meta.$store.dispatch("ual/alert_show", { show });
                        next();
                    } else if (hasModel && hasRole) {
                        show = false;
                        to.meta.$store.dispatch("ual/alert_show", { show });
                        next();
                    } else {
                        show = true;
                        const requestedRouteName = to.name;
                        const requestedRoutePath = to.path;
                        to.meta.$store.dispatch("ual/alert_show", { show });
                        to.meta.$store.dispatch("ual/module_name", { requestedRouteName });
                        if (requestedRoutePath === '/dashboard') {
                            next('/');
                        }
                        //  else {
                        //     next();
                        // }
                    }
                } else {
                    return next({ path: '/signin', query: { returnUrl: to.path } });
                }
            } else {
                return next({ path: '/signin', query: { returnUrl: to.path } });
            }
        } catch (error) {
            console.error(error);
            show = true;
            next('/401');
        }

    }
    if (!authorize) {
        next();
    }
});

export default router;